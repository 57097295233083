.modal-text-input {
    height: 24px;
    background: #555555;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80px;
    padding: 1px 4px;
    margin: 2px 0;
    border-radius: 4px;
    cursor: text;
}

.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.modal-text-input-expanded {
    width: 600px;
}
