.MemorySummary {
    cursor: pointer;
}

.MemorySummary-content {
    display: flex;
}

.MemorySummary-collapsed .MemorySummary-content-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MemorySummary-content *:first-child {
    margin-inline-end: 8px;
    flex-shrink: 0;
    flex-grow: 0;
}

.MemorySummary-content svg:last-child {
    margin-inline-start: 8px;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-end;
}

.MemorySummary small {
    display: block;
    text-align: end;
}
