a.SidebarBackLink {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    text-decoration: none;
}
a.SidebarBackLink > *:first-child {
    margin-left: 4px;
}
a.SidebarBackLink:hover {
    background: rgba(255, 255, 255, 0.05);
}
