.SidebarFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 380px;
    background: #444444;
    border-top: solid 1px #555555;
    padding: 16px;
    font-size: 14px;
}

.SidebarFooter a {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: bold
}
