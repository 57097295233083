.tildeToggle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background: darkgreen;
    font-size: 12px;
    line-height: 18px;
    align-self: center;
    user-select: none;
}

.tildeToggle.active {
    background: darkorange;
    font-size: 24px;
}
