.NewMemoryForm {
  border: solid 1px #555555;
  border-radius: 10px;
  padding: 10px;
  background: #333333;
}

@media screen and (max-width: 379px) {
  .NewMemoryForm {
    width: calc(100vw - 20px);
  }
}

@media screen and (min-width: 380px) {
  .NewMemoryForm {
    width: 340px;
  }
}

.NewMemoryForm * {
  /*font-family: 'Open Sans Hebrew', sans-serif !important;*/
}

.NewMemoryForm h2 {
  margin-bottom: 12px;
}

.NewMemoryForm h3 {
  font-size: 16px;
  margin: 14px 0 0 0;
}

.NewMemoryForm p {
  margin: 0 0 4px 0;
}

.NewMemoryForm-is-anonymous {
  margin: 8px 0;
}

.NewMemoryForm .MuiInput-root {
  margin: 4px 0;
}

.NewMemoryForm-is-anonymous label {
  cursor: pointer;
}

.NewMemoryForm-is-anonymous small {
  display: block;
  margin-inline-start: 21px;
}

/* fix RTL bug, margin being on the right */
.NewMemoryForm .MuiButton-root span {
  margin: 0 0 0 8px;
}
