.VenueTagsRow {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 4px 12px;
}

.VenueTagsRow-tag {
    padding: 2px 6px;
    background: purple;
    font-size: 12px;
    border-radius: 6px;
}
