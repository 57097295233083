.ContentScreen {
    padding: 32px;
}

.ContentScreen-header a {
    cursor: pointer;
    text-decoration: inherit;
}

.ContentScreen-body {
    max-width: 660px;
    margin: 0 auto;
}

.ContentScreen-body p {
    margin: 16px 0;
}

.ContentScreen-body h3 {
    margin: 24px 0 16px 0;
}

@media screen and (min-width: 1100px) {
    .ContentScreen-header {
        position: fixed;
        right: 32px;
        top: 32px;
    }

    .ContentScreen-body h1 {
        font-size: 36px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1099px) {
    .ContentScreen-header {
        margin-bottom: 16px;
    }
}
