.YearRangeFilter {
    display: flex;
    flex-direction: row;
}

.YearRangeFilter-slider {
    width: 100%;
    height: 30px;
}

.YearRangeFilter-slider-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    line-height: 1;
    height: 30px;
    background: #ffdd00;
    color: purple;
    text-align: center;
    cursor: grab;
    font-weight: bold;
    border-radius: 8px;
    transition: all 0.05s, background 0.5s;
}

.YearRangeFilter-slider-thumb:hover, .YearRangeFilter-slider-thumb:focus {
    background: #ffee55;
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 10px;
}

.YearRangeFilter-slider.all-years .YearRangeFilter-slider-thumb {
    display: none;
}

.YearRangeFilter-slider-track {
    top: 13px;
    height: 4px;
    background: #666666;
}

.YearRangeFilter-all-years-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 16px;
    background: #666;
    border-radius: 8px;
    padding: 0 8px;
    cursor: pointer;
    line-height: 1;
}
.YearRangeFilter-all-years-toggle.active {
    background: purple;
}

.YearRangeFilter-all-years-toggle input {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
}
