.SearchBar {
    width: 100%;
    position: relative;
}

.SearchBar__control {
    background: #222222;
    color: white;
}

.SearchBar-not-found-option {
    padding: 8px 12px;
}

.SearchBar-close-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}
