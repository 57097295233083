.venuesTable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.venuesTable thead {
  position: sticky;
  top: 44px;
  background: #444444;
}

.venuesTable th, .venuesTable td {
  padding: 1px 6px;
  text-align: right;
  vertical-align: top;
}
.venuesTable th:first-child, .venuesTable td:first-child {
  padding-right: 16px;
}

.venuesTable input,
.venuesTable textarea {
  background: #555555;
  border: none;
  color: #eeeeee;
  font-family: inherit;
  font-size: inherit;
  border-radius: 4px;
  padding: 1px 4px;
  margin: 2px -4px;
  width: 100%;
}

.venuesTable input::-webkit-outer-spin-button,
.venuesTable input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.venuesTable input[type=number] {
  -moz-appearance: textfield;
}

.venuesTable td, .venuesTable tr:not(:first-child) th {
  border-top: solid 1px #666666;
}

.venuesTable tr:not(:first-child) th {
  padding-top: 12px;
}

.venuesTable td[dir=ltr] {
  text-align: left;
}

.venuesTable .yearInputs {
  display: flex;
  align-items: center;
}

.venuesTable .yearInput {
  width: 48px;
  margin-left: 4px;
}

.venuesTable .long-text-preview {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.VenueTableRowEdit-actions {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 4px;
}
