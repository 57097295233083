.VenuePreviewPane {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444444;
    padding: 16px;
    z-index: 1000;
    box-shadow: 0 -7px 6px rgba(0, 0, 0, 0.5);
    user-select: none;
}

.VenuePreviewPane .swipe-bar {
    display: flex;
    justify-content: center;
    border-bottom: solid 1px #555555;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.VenuePreviewPane h2 {
    font-size: 24px;
}
