.venuesIndexScreen-header {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    position: sticky;
    top: 0;
    height: 44px;
    background: #444444;
    z-index: 100;
}

.venuesIndexScreen-header h1 {
    font-size: 20px;
    margin-left: 10px;
}
