.SearchButton {
    width: 32px;
    height: 32px;
    background: #222;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
