.VenueDetails-content {
  padding: 16px;
}

.VenueDetails-address {
  cursor: pointer;
}

.VenueDetails h1 {
  font-size: 24px;
}

.VenueDetails h2 {
  font-size: 18px;
}

.VenueDetails-contribute {
  margin-top: 24px;
  padding-top: 24px;
  border-top: solid 1px #555555;
}

ul.VenueDetails-venue-memories, ul.VenueDetails-venue-memories li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.VenueDetails-venue-memories li {
  margin: 8px 0;
}
