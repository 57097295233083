.DesktopApp {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.DesktopApp-sidebar {
    width: 380px;
    flex-grow: 0;
    flex-shrink: 0;
    box-shadow: rgba(255, 255, 255, 0.2) 0 0 20px;
    background: #444444;
    z-index: 100;
    overflow: scroll;
    padding-bottom: 52px;
}
