@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);

body {
  background: #282828;
  margin: 0;
  font-family: 'Open Sans Hebrew', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fafafa;
  font-size: 16px;
}

a {
  color: #fafafa;
}

a.block-link {
  color: inherit;
  text-decoration: inherit;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
