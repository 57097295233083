.ExploreSidebarHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ExploreSidebarHeader h2 {
    flex-grow: 1;
}
