.TagsFilter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px 0;
}

.TagsFilter label {
    padding: 2px 6px;
    background: #666;
    font-size: 12px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: #cccccc;
}

.TagsFilter label:not(:last-child) {
    margin-left: 8px;
}

.TagsFilter label.selected {
    background: purple;
    color: #fafafa;
}

.TagsFilter label.selected-by-default {
    /*background: #6b4c6d;*/
    color: #fafafa;
}

.TagsFilter label input {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
}
