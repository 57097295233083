.MobileNav {
    width: 100%;
    height: 50px;
    background: #333333;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
}

.MobileNav > a {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-top: solid 2px transparent;
    padding-bottom: 2px;
}

.MobileNav > a.active {
    border-top: solid 2px white;
}
