.ExploreMapContainer {
  height: 100vh;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

@media screen and (max-width: 719px) {
  .ExploreMapContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 50px);
  }
}

.ExploreMapContainer-filters-bar-container {
  position: absolute;
  right: 32px;
  left: 32px;
  bottom: 38px;
}

.ExploreMapContainer-city-filter-container {
  position: absolute;
  right: 32px;
  top: 32px;
  display: flex;
  gap: 16px;
  align-items: center;
}

@media screen and (max-width: 719px) {
  .ExploreMapContainer-filters-bar-container {
    right: 16px;
    left: 16px;
    bottom: 38px;
  }

  .ExploreMapContainer-city-filter-container {
    right: 16px;
    top: 16px;
  }
}
