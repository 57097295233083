.SidebarHeader {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #444444;
    border-bottom: solid 1px #555555;
    position: sticky;
    top: 0;
}

.SidebarHeader h2 {
    font-size: 16px;
}

.SearchHeader-standard-padding {
    padding: 0 16px;
}
