.ResultsListItem {
  display: grid;
  padding: 16px;
  border-bottom: solid 1px #555555;
  grid-template-areas:
    "name tags"
    "address address"
    "memories memories";
}

.ResultsListItem h3 {
  margin-bottom: 4px;
  grid-area: name;
}

.ResultsListItem p {
  margin: 4px 0;
}

.ResultsListItem:hover {
  background: rgba(255, 255, 255, 0.05);
}

.ResultsListItem p:last-child {
  margin-bottom: 0;
}

.ResultsListItem-tags {
  grid-area: tags;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ResultsListItem-address {
  grid-area: address;
}

.ResultsListItem-memories {
  margin-top: 8px;
  grid-area: memories;
  overflow: hidden;
}
