.FiltersBar {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.FiltersBar-year-range-container, .FiltersBar-tags-container {
  background: rgba(80, 80, 80, 0.8);
  padding: 6px 12px;
  border-radius: 8px;
}

.FiltersBar-tags-container {
  width: auto;
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 12px;
  max-width: 100%;
  overflow-x: auto;
}

.FiltersBar-tags-container span:first-child {
  margin-left: 8px;
}

.FiltersBar-year-range-container {
  width: 100%;
}
