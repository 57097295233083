.MobileApp-explore-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 50px;
    background: #333333;
    z-index: 100;
    overflow-y: scroll;
}
