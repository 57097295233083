.SiteLogo {
    display: flex;
    align-items: center;
    height: 36px;
    position: relative;
}

.SiteLogo h1 {
    font-size: 36px;
    line-height: 30px;
    height: 36px;
    font-weight: 600;
}

.SiteLogo-beta-tag {
    display: block;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 30px;
    color: rgba(255, 255, 255, 0.66);
}
