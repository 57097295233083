@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.LocationEditor {
    position: relative;
}

.LocationEditor-address-line {
    position: relative;
}

.LocationEditor-address-line input {
    padding-left: 24px !important;
}

.LocationEditor-address-line .geocoding-spinner {
    position: absolute;
    left: 8px;
    top: 6px;
    width: 16px;
    height: 16px;
    display: flex;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.LocationEditor-coordinates {
    position: absolute;
    top: 32px;
    right: -4px;
    width: 216px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    overflow: hidden;
    background: #555555;
    padding: 8px;
    z-index: 1000;
}
.LocationEditor-map {
    height: 200px;
}
