.CityFilter input {
    /*display: none;*/
    position: absolute;
    left: 0;
    cursor: pointer;
}

.CityFilter-select__control * {
    cursor: pointer;
}
